<template>
    <div class="message-text">
        <div class="open-chat">
            <div class="noti-title">
                <img class="m-r-8" src="@/assets/images/icons/docu.png" width="24px" height="24px" />
                <span>인증 서류를 준비해주세요!</span>
            </div>
            <div class="noti-body">서비스 이용을 위해서는 3가지 인증이 필요합니다.</div>
            <div class="noti-blue-box">
                <div class="spoqa-f-bold c-grey-07 f-12">필수 제출 서류</div>
                <div class="item" v-for="(val, i) in need" :key="i">
                    <div class="dot bg-black m-r-4 m-b-4" />
                    <span class="f-14 spoqa-f-regular" v-html="val" />
                </div>
            </div>
            <div class="noti-body">대리 발급을 원하신다면 이 채팅방에 문의 남겨주세요.</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ActionPrepareVerification',
    computed: {
        need() {
            return ['학력인증(졸업증명서 등)', '직장 인증(명함, 재직증명서 등)', '혼인관계 인증(증명서 상세)']
        },
    },
}
</script>

<style scoped lang="scss">
.message-text {
    padding-top: 0;
}
.open-chat {
    padding-top: 12px;
    padding-bottom: 12px;
    .noti-title {
        @include spoqa-f-bold;
        font-size: 16px;
        color: #111111;
        padding: 0 2px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
    }
    .noti-body {
        font-size: 14px;
        margin-top: 8px;
        color: #111111;
        line-height: 26px;
    }
    .noti-blue-box {
        margin-top: 8px;
        background: $grey-01;
        border-radius: 8px;
        color: #111111;
        padding: 4px 8px;
        font-size: 14px;
        line-height: 24px;

        .item {
            display: flex;
            align-items: center;
            font-size: 14px;
            line-height: 24px;
        }
    }
}
</style>
